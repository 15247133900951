<template>
  <div class="outer-container">
    <div class="header">
      <div :style="{'background-image': `url(${headerImage}`}"
           class="header-image"></div>
      <!-- Header image -->
    </div>
    <!-- Header section -->
    <div class="content-container">
      <Step
          v-for="(item, index) in DSSteps"
          :key="index"
          :description="item.description"
          :stepNumber="`${index + 1}`"
          :stepName="item.name"
      >
        <div class="figures">
          <Figure
              textAlignment="center"
              caption="Primary and secondary colors for the system. These colors are broken into tints and shades which make them robust to allow for a diverse set of use-cases"
              v-if="(index + 1) == 1"
          >
            <div class="fig pallete"></div>
          </Figure>
          <!-- figure -->
          <Figure
              textAlignment="center"
              caption="Typescale that illustrates the gradation of sizing and spacing of the typography"
              v-if="(index + 1) == 1"
          >
            <div class="fig type-scale"></div>
          </Figure>
          <!-- figure -->
          <Figure
              textAlignment="center"
              caption="Spacing scale. This sets the foundation of how elements are place in relation to each other."
              v-if="(index + 1) == 1"
          >
            <div class="fig space-scale"></div>
          </Figure>
          <!-- figure -->
          <Figure
              textAlignment="center"
              caption="Spacing scale. This sets the foundation of how elements are place in relation to each other."
              v-if="(index + 1) == 2"
          >
            <div class="fig cols"></div>
          </Figure>
          <!-- figure -->
        </div>
        <!-- Figures -->
        <div class="videos" v-if="(index + 1) == 3">
          <InteractionVideo
              caption="Card-like carosel interaction on mobile for swiping through content"
              :video="carousel"
              videoWidth="calc(100vw - 4rem)"
              videoHeight="33.5rem"
              :isDesktop="false"
              coverHeight="31.5rem"
          />
          <InteractionVideo
              caption="Bottom sheet audio console pull out on mobile"
              :video="console"
              videoWidth="calc(100vw - 4rem)"
              :isDesktop="false"
              coverHeight="47.5rem"
          />
        </div>
        <!-- Videos -->
      </Step>
      <!-- Step -->
      <SubtitleBody :subtitle="final.subtitle"
                    :description="final.description"/>
    </div>
    <!-- Content container -->
    <!-- Center -->
  </div>
</template>

<script>
import {store} from "@/store/store.js";
import {colors} from "@/core/colors.js";
import {written} from "@/core/data/written_data.ts";
import Step from "@/components/info/desktop/Step.vue";
import VueMarkdown from "vue-markdown";
import Figure from "@/components/info/desktop/Figure.vue";
import InteractionVideo from "@/components/info/InteractionVideo.vue";
import CarouselVideo
  from "@/assets/videos/design_system/card_carousel_mobile.mp4";
import AudioConsole
  from "@/assets/videos/design_system/audio_console_mobile_transition.mp4";
import SubtitleBody from "@/components/info/desktop/Subtitle_Body.vue";
import HeaderImage from "@/assets/images/design_system/pages_cover.webp";
import Layout from "@/styles/layout.css";

export default {
  store: store,
  data() {
    return {
      carousel: CarouselVideo,
      console: AudioConsole,
      headerImage: HeaderImage,
      center: Layout.center,
    };
  },
  components: {
    Step,
    Figure,
    InteractionVideo,
    SubtitleBody,
  },
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.$store.getters.isDark, this.$store);
    },
    DSContent: function () {
      return written.projects.SDdesignSystem;
    },
    DSSteps: function () {
      return written.projects.SDdesignSystem.details.steps;
    },
    final: function () {
      return written.projects.SDdesignSystem.details.verification;
    },
    checkStep: function (index, step) {
      return index + 1 == step ? true : false;
    },
  },
};
</script>

<style scoped>
p {
  margin: 0 !important;
  padding: 0 !important;
}

a:link,
a:focus,
a:visited {
  color: #fa713f;
}

.outer-container {
  width: 100vw;
  margin: 3rem 0 100% 0;
  display: flex;
  flex-flow: column;
}

.header-image {
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 71.25rem;
  height: 13.25rem;
  background-size: contain;
}

.header {
  width: 100vw;
  height: 13.25rem;
  position: relative;
  top: 0.1rem;
}

.content-container {
  top: 3rem;
}

.pallete {
  background: url("../../../assets/images/design_system/color_pallete.webp") no-repeat;
  height: 12rem !important;
}

.type-scale {
  background: url("../../../assets/images/design_system/type_scale.webp") no-repeat;
  height: 16rem !important;
}

.space-scale {
  background: url("../../../assets/images/design_system/space_scale.webp") no-repeat;
  height: 10rem !important;
}

.cols {
  background: url("../../../assets/images/design_system/columns.webp") no-repeat;
  height: 15rem !important;
  width: auto;
}

.fig {
  background-position: center;
  height: 20rem;
  background-size: calc(100vw - 4rem);
}

.figure {
}

.figures {
  display: grid !important;
  grid-template-columns: auto !important;
  place-items: center !important;
}

.caption {
  width: inherit !important;
}

.steps-body-container {
  display: grid;
  width: 100vw;
  place-items: start;
  box-sizing: border-box;
}

.steps-body-container > * {
  box-sizing: border-box;
  margin: 0 auto;
}

.steps-body-container > p {
  padding: 0 2rem !important;
}

.videos {
  width: 100vw;
  height: auto;
}
</style>